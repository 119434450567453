<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Member Statistics</h4>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button> -->
      </div>
      <div class="mb-2">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Search by keyword"
              label-for="v-search-keyword"
            >
              <b-form-input
                id="h-search-keyword"
                v-model="filter"
                type="text"
                placeholder="search here..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="memeberItems"
        sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @row-clicked="showDetails"
      >
        <template #cell(avatar)="data">
          <b-avatar
            v-if="data.value === null || data.value === ''"
            :text="getUserText(data.item.first_name, data.item.last_name)"
            variant="success"
          />
          <b-avatar
            v-else
            :src="getImage(data.value)"
            variant="light-success"
          />
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="per_page"
        size="lg"
        class="mt-3 pagination-success"
        align="center"
        @change="onPageChange"
      />
    </b-card>

    <b-modal
      id="app-modal"
      :title="is_update ? 'Uptate Member' : 'Add New'"
      hide-footer
      size="huge"
      @hide="hide"
    >
      <b-card-text>
        <Auth
          type="member"
          :hide="hide"
          :show-details="showDetails"
          :update="is_update"
          :edited="is_edited"
          :disable-editted="disableEditted"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BAvatar, BPagination,
  BFormInput, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Auth from './components/RegisterUser/Auth.vue'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BAvatar,
    BPagination,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    Auth,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      fields: [{ key: 'id', label: '#ID' }, { key: 'avatar', label: 'avatar' }, { key: 'fullName', label: 'full name', sortable: true }, { key: 'searches', label: 'searches', sortable: true }, { key: 'recommendations', label: 'Recommendations', sortable: true }, { key: 'reviews', label: 'reviews', sortable: true }, { key: 'messages', label: 'messages', sortable: true }],
      currentPage: 1,
      per_page: 10,
      sortBy: 'id',
      sortDesc: false,
      searchTerm: '',
      filter: null,
      filterOn: [],
      is_edited: false,
    }
  },
  computed: {
    member_statistics() {
      return this.$store.state.statistic.member_statistics
    },
    memeberItems() {
      return this.member_statistics.map(item => ({
        ...item,
        fullName: `${item.first_name} ${item.last_name}`,
      }))
    },
    rows() {
      return this.$store.state.statistic.total
    },
    register: {
      get() {
        return this.$store.state.auth.userRegister
      },
      set(value) {
        return value
      },
    },
  },
  watch: {
    '$store.state.user.member': {
      handler(oldValue, newValue) {
        let item
        if (oldValue.id !== undefined) {
          item = oldValue
        } else {
          item = newValue
        }
        this.register.id = item.id
        this.register.first_name = item.first_name
        this.register.last_name = item.last_name
        this.register.phone = item.phone
        this.register.email = item.email
        this.register.address1 = item.address1
        this.register.city = item.city
        this.register.state = item.state
        this.register.zip_code = item.zip_code
        this.register.county = item.county
        this.register.user_access = item.user_access
        this.register.profession = item.profession
        this.$store.dispatch('review/getMemberReviews', { member_id: item.id, per_page: 3, page: 1 })
        this.is_update = true
        this.showModal()
        const dom = this
        setTimeout(() => {
          dom.is_edited = false
        }, 1000)
      },
    },
    '$store.state.auth.userRegister': {
      handler() {
        this.is_edited = true
      },
      deep: true,
    },
  },
  mounted() {
    this.getVendorStatistics()
  },
  methods: {
    getVendorStatistics() {
      return this.$store.dispatch('statistic/getMemberStatistics', { page: 1, per_page: this.per_page })
    },
    onPageChange(page) {
      this.$store.dispatch('statistic/getMemberStatistics', { page, per_page: this.per_page })
    },
    getUserText(firstName, lastName) {
      const firstChar = firstName.charAt(0)
      const lastChar = lastName.charAt(0)
      return firstChar + lastChar
    },
    getImage(image) {
      return process.env.VUE_APP_STORAGE_PATH + image
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    disableEditted() {
      this.is_edited = false
    },
    hide(e) {
      if (this.is_edited === true) {
        e.preventDefault()
        this.$swal({
          title: 'Do you want to save changes',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save Changes',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.is_edited = false
            this.updateUser()
            this.$bvModal.hide('app-modal')
          } else if (result.dismiss === 'cancel') {
            this.is_edited = false
            this.$bvModal.hide('app-modal')
            const dom = this
            setTimeout(() => {
              dom.$store.dispatch('auth/emptyData')
            }, 500)
          }
        })
      } else {
        this.$bvModal.hide('app-modal')
        this.is_edited = false
        const dom = this
        setTimeout(() => {
          dom.$store.dispatch('auth/emptyData')
        }, 500)
      }
    },
    updateUser() {
      this.$store.dispatch('auth/updateRegisterProfile', this.register)
      const dom = this
      setTimeout(() => {
        dom.$store.dispatch('user/getMembers', { page: 1, per_page: 10, user_type: 'member' })
      }, 100)
    },
    showDetails(item) {
      this.$store.dispatch('user/getMember', item.id)
    },
  },
}
</script>

<style>

</style>
